import { lazy } from 'react'

async function doRetry (error, retryCount) {
  if (retryCount >= 5) throw error
  if (!error.message.startsWith('Failed to fetch dynamically imported module: ')) throw error

  // sleep 1 second
  await new Promise(resolve => setTimeout(resolve, 1000))

  try {
    const url = new URL(
      error.message
        .replace('Failed to fetch dynamically imported module: ', '')
        .trim()
    )
    // this assumes that the exception will contain this specific text with the url of the module
    // if not, the url will not be able to parse and we'll get an error on that
    // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
    // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
    url.searchParams.set('t', `${+new Date()}`)
    // The vite-ignore needs to be added inside the import otherwise a warning message is displayed
    // on the console
    return await import(
      /* @vite-ignore */
      url.href
    )
  } catch (e) {
    return doRetry(e, retryCount + 1)
  }
}

function lazyWithRetry (importer) {
  const retryImport = async () => {
    try {
      return await importer()
    } catch (error) {
      return doRetry(error, 1)
    }
  }
  return lazy(retryImport)
}

export default lazyWithRetry

import React from 'react'
import { connect } from 'react-redux'

const Loading = ({ isLoading }) => {
  if (!isLoading) return null
  return (
    <div className='loading-indicator'>
      <span className='loading-indicator-text'>
        communicating with server
      </span>
    </div>
  )
}

const mapStateToProps = state => ({
  isLoading: state.gui.loadingCalls.length > 0
})

export default connect(mapStateToProps)(Loading)

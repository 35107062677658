/* eslint-disable max-len */
import React, { Component, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap'
import * as Sentry from '@sentry/browser'

function shouldSwallowError () {
  /**
   * because of our legacy redux setup, we need to swallow errors that happen
   * immediately after we set the LOGIN_REDIRECT_TIME which is set in core.js
   *
   * These errors happen after the browser has already gotten a redirect instruction
   * but they still fire off to sentry.
   *
   * This can be removed when we're no longer using redux async server calls
   */
  const ltrStr = localStorage.getItem('LOGIN_REDIECT_TIME')
  if (!ltrStr || !ltrStr.match(/^\d+$/)) return false
  const loginRedirectTime = parseInt(ltrStr, 10)
  const currentTime = new Date().getTime()
  if (currentTime - loginRedirectTime < 1000) {
    return true
  }
  return false
}

function ErrorDisplay ({ error }) {
  const [showDetails, setShowDetails] = useState(false)

  if (!error) return null

  return (
    <>
      <Button onClick={() => setShowDetails(!showDetails)}>Show details</Button>
      {showDetails && (
        <>
          <h2>Error Details</h2>
          <div
            className='mx-4' style={{
              fontFamily: 'monospace',
              whiteSpace: 'pre-wrap',
              border: '1px solid #ccc',
              textAlign: 'left',
              padding: '3px'
            }}
          >
            {error.stack}
          </div>
        </>
      )}
    </>
  )
}

class SentryBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ error })
    if (shouldSwallowError()) {
      console.log('swallowing error', error)
      return
    }
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render () {
    if (this.state.error) {
      // render fallback UI
      return (
        <Container>
          <Row>
            <Col className='text-center'>
              <h1>Uh oh!</h1>
              <p>We had a problem.  We've automatically reported this to our developers.</p>
              <p>If you want to provide any extra feedback, click the button below.  Otherwise, please try reloading the page.</p>
              <Button onClick={() => Sentry.showReportDialog()} className='mr-1'>Report feedback</Button>
              <ErrorDisplay error={this.state.error} />
            </Col>
          </Row>
        </Container>
      )
    }
    // when there's not an error, render children untouched
    return this.props.children
  }
}

export default SentryBoundary

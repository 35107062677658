import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'
import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { getCustomerIOKey } from '../utils/getEnvironments'
import jwtDecode from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'

const analytics = AnalyticsBrowser.load(
  {
    writeKey: getCustomerIOKey()
  }
)

export const AnalyticsContext = createContext(analytics)

function getCompanyUUID (pathname) {
  const match = pathname.match(/\/c\/[a-f0-9-]{36}/)
  if (match) {
    return match[0].replace('/c/', '')
  }
  return null
}

function AnalyticsProvider ({ children }) {
  const location = useLocation()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [authToken, setAuthToken] = useState(null)

  useEffect(() => {
    if (authToken) {
      try {
        const email = jwtDecode(authToken).email
        analytics.identify(email)
      } catch (error) {
        console.error('Error initializing analytics', error)
      }
    }
  }, [authToken])

  useEffect(() => {
    if (isAuthenticated && !authToken) {
      getAccessTokenSilently().then((token) => {
        setAuthToken(token)
      }).catch((err) => {
        console.error('Error getting token in analytics', err)
      })
    }
  }, [isAuthenticated, authToken])

  useEffect(() => {
    // replace all UUIDs for better grouping in analytics
    const cleaned = location.pathname.replace(/[a-f0-9-]{36}/g, ':id')
    const companyUUID = getCompanyUUID(location.pathname)
    analytics.page(cleaned, { companyUUID })
  }, [location])

  // Always provide the initialized analytics instance
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

function AnalyticsWrapper ({ children }) {
  return (
    <AnalyticsProvider>
      {children}
    </AnalyticsProvider>
  )
}

export default AnalyticsWrapper

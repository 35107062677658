import { Auth0Provider } from '@auth0/auth0-react'
import { audience, clientID } from '../../config'

function Auth0WithNavigate ({ children }) {
  return (
    <Auth0Provider
      domain='chainio.auth0.com'
      clientId={clientID}
      authorizationParams={{
        audience,
        redirect_uri: `${window.location.origin}/auth_callback`
      }}
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0WithNavigate

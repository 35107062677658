const isProductionEnvironment = () => import.meta.env.VITE_CHAINIO_ENV === 'production'
const isRedEnvironment = () => import.meta.env.VITE_CHAINIO_ENV === 'red'
const isLocalEnvironment = () => import.meta.env.VITE_CHAINIO_ENV === 'test'
const getEnvironmentPrefix = () => (isProductionEnvironment() ? '' : 'red-')

const getPortalUrl = () => {
  if (isLocalEnvironment()) {
    return 'http://localhost:3000'
  }
  if (isRedEnvironment()) {
    return 'https://red-portal.chain.io'
  }
  return 'https://portal.chain.io'
}

const getCustomerIOKey = () => {
  if (isProductionEnvironment()) {
    return '15378f4c897724e3e470:febd697370d3d814239a'
  }
  return '5faf57ff51a304f8d15f:7b05b492b935e6706419' // local
} // these are public access keys for use in the browser and do not need to be secured

export {
  isProductionEnvironment, isRedEnvironment, isLocalEnvironment, getEnvironmentPrefix, getPortalUrl, getCustomerIOKey
}

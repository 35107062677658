import * as Sentry from '@sentry/browser'
import React, { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'
import ReactGA from 'react-ga4'
import {
  ApolloClient, InMemoryCache, ApolloProvider, createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import rootReducer from './reducers/index'
import App from './App'
import Loading from './elements/ReduxLoading'
import './index.css'
import SentryBoundary from './SentryBoundary'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss'

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import { apiUrl, generateHeadersWithAuth } from './config'
import apolloTypePolicy from './utils/apolloTypePolicy'
import { isRedEnvironment, isProductionEnvironment, isLocalEnvironment } from './utils/getEnvironments'

const getSentryEnvironment = () => {
  if (isProductionEnvironment()) {
    return 'production'
  }
  if (isRedEnvironment()) {
    return 'development'
  }

  return 'local'
}

const GA_ID = getSentryEnvironment() === 'production' ? 'G-DBTJ2TR3NE' : 'G-K2G7JKYF27'
ReactGA.initialize(GA_ID)
ReactGA.send('pageview')

Sentry.init({
  environment: getSentryEnvironment(),
  dsn: 'https://87d09ac7658543dbb59990795a05218a@sentry.io/1444181',
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    /magicbell/i
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    Sentry.extraErrorDataIntegration()]
})

const apolloAuthContext = setContext(async (_, { headers }) => {
  return {
    headers: generateHeadersWithAuth({
      'Content-Type': 'application/json'
    })
  }
})

const apolloHttpLink = createHttpLink({
  uri: `${apiUrl}/graphql`
})

const client = new ApolloClient({
  link: apolloAuthContext.concat(apolloHttpLink),
  cache: new InMemoryCache({
    typePolicies: apolloTypePolicy
  })
})

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <SentryBoundary>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Loading />
        <App />
      </Provider>
    </ApolloProvider>
  </SentryBoundary>
)

if (isLocalEnvironment()) {
  document.title = `LOC | ${document.title}`
} else if (isRedEnvironment()) {
  document.title = `DEV | ${document.title}`
}
